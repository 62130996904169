import React from 'react'

const ErrorPage = () => {
  return (
    <h1>
        This page doesn't exist :(
    </h1>
  )
}

export default ErrorPage