import ProjectRow from "../components/ProjectRow/ProjectRow"

const Projects = () => {
  return (
    <div>
      <ProjectRow />
      <ProjectRow />
      <ProjectRow />
    </div>
  )
}

export default Projects